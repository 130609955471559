import React, { useState } from 'react'
import { Button, Modal, } from 'react-bootstrap';
import Cancel from '../../assets/cross.svg';
import styled from 'styled-components';
import "./Modals.css";
import LockIcon from "../../assets/lock.svg";
import EmailSendIcon from "../../assets/email-send.svg";
import SuccessIcon from "../../assets/success.svg";

export default function ForgotPassword(props) {

    const [email, setEmail] = useState('');
    const [tempPassword, setTempPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isResendSuccess, setIsResendSuccess] = useState(false);
    const [modalState, setModalState] = useState(1); // 1 for email modal, 2 for temp password modal, 3 for password modal

    const handleEmailChange = (e) => {
        setEmailError('');
        setEmail(e.target.value);
    }

    const handleTempPasswordChange = (e) => {
        setEmailError('');
        setTempPassword(e.target.value);
    }

    const handleNewPassword1Change = (e) => {
        setEmailError('');
        setNewPassword1(e.target.value);
    }

    const handleNewPassword2Change = (e) => {
        setEmailError('');
        setNewPassword2(e.target.value);
    }

    const email_submit = async () => {
        
        if (email == '') {
            setEmailError('Email Required');
            return;
        }
        setIsProcessing(true);

        const response = await fetch(`${process.env.GATSBY_RS2_BASE_URL}index.php?route=account/forgotten/forget_pass`, {
            method: 'POST',
            body: new URLSearchParams({
                email: email,
                prodocs: true,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        });
        console.log("response", response);
        setIsProcessing(false);

        let res = await response.text(); // Read response as plain text
        res = res.replace(/^k/, '');
        res = JSON.parse(res); // Log the raw response
        console.log(res)
        if (res?.success == true) {
            setModalState(2);
        }
        else if (res?.success == false) {
            setEmailError(res?.message);
        }
        else {
            console.error('Error during email submit');
        }

    }

    const resendEmail = async () => {
        
        if (email == '') {
            setEmailError('Email Required');
            return;
        }

        const response = await fetch(`${process.env.GATSBY_RS2_BASE_URL}index.php?route=account/forgotten/resend_email`, {
            method: 'POST',
            body: new URLSearchParams({
                email: email,
                prodocs: true,
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        });
        console.log("response", response);

        let res = await response.text(); // Read response as plain text
        res = res.replace(/^k/, '');
        res = JSON.parse(res); // Log the raw response
        console.log(res)
        if (res?.success == true) {
            setIsResendSuccess(true);
        } else {
            console.error('Error during resend email');
        }

    }

    const tempPassword_submit = async () => {

        setIsResendSuccess(false);

        if (tempPassword == '') {
            setEmailError('Password Required');
            return;
        }
        setIsProcessing(true);

        const response = await fetch(`${process.env.GATSBY_RS2_BASE_URL}index.php?route=account/forgotten/temp_pass`, {
            method: 'POST',
            body: new URLSearchParams({
                otp: tempPassword,
                email: email
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        });
        console.log("response", response);
        setIsProcessing(false);

        let res = await response.text(); // Read response as plain text
        res = res.replace(/^k/, '');
        res = JSON.parse(res); // Log the raw response
        console.log(res)
        if (res?.success == true) {
            setModalState(3);
        }
        else if (res?.success == false) {
            setEmailError(res?.message);
        }
        else {
            console.error('Error during password submit');
        }

    }

    const newPassword_submit = async () => {
        if (newPassword1 == '' || newPassword2 == '') {
            setEmailError('Password Required');
            return;
        }
        if (newPassword1 != newPassword2) {
            setEmailError("Password doesn't match");
            return;
        }
        setIsProcessing(true);

        const response = await fetch(`${process.env.GATSBY_RS2_BASE_URL}index.php?route=account/forgotten/new_pass`, {
            method: 'POST',
            body: new URLSearchParams({
                password: newPassword1,
                confirm: newPassword2,
                email: email,
                prodocs: true
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        });
        console.log("response", response);

        let res = await response.text(); // Read response as plain text
        res = res.replace(/^k/, '');
        res = JSON.parse(res); // Log the raw response
        console.log(res)
        if (res?.success == true) {
            setIsSuccess(true);
            setNewPassword1('');
            setNewPassword2('');
            setIsProcessing(false);

            setTimeout(() => {
                props.setShowModal(false);
            }, 3000);
        }
        else if (res?.success == false) {
            setEmailError(res?.message);
            setIsProcessing(false);
        }
        else {
            console.error('Error during password submit');
        }

    }

    return (
        <Modal
            onHide={() => props.setShowModal(true)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.showModal}
            style={{ opacity: '1' }}
            className='d-flex justify-content-center align-items-center login-modal'
        >
            <img className="mt-5" src={modalState === 1 ? LockIcon : modalState === 2 ? EmailSendIcon : SuccessIcon} style={{ width: '90px', height: 'auto' }} />

            <Modal.Header>
                <img onClick={() => props.setShowModal(false)} src={Cancel} style={{ width: "26px", position: 'absolute', right: '1.5rem', top: '1.5rem', backgroundColor: 'transparent', cursor: 'pointer', border: 'none' }} />

                <Modal.Title>
                    <p className="mt-1 mb-0" style={{ color: "#DE841E", height: "38px", fontFamily: 'Rajdhani', fontWeight: "bold", fontSize: "26px", opacity: 1, lineHeight: "39px" }}>{modalState === 1 ? 'Forgot your password?' : modalState === 2 ? 'Check your email!' : 'Success!'}</p>
                </Modal.Title>
            </Modal.Header>
            <form className="w-100">
                <Modal.Body>

                    <p className="text-center p-2" style={{ color: "#212e3380", fontFamily: 'Roboto', fontWeight: "400", fontSize: "16px", opacity: 1, lineHeight: "24px" }}>
                        {modalState === 1 ? 'Don’t worry. Just enter the email address associated with your account and we’ll send you an email with a temporary password and instructions.' : modalState === 2 ? 'Please check your email for a temporary password. Once you have copied and pasted the temporary password into the form field, click the “Reset Password” button to proceed.' : 'Enter a new password in the forms below and click the “Confirm Password” button to activate your new password.'}
                    </p>

                    <div class="mb-3 pt-2" style={{ color: "#212E33", fontFamily: 'Rajdhani', fontWeight: "400", fontSize: "17px", opacity: 1, lineHeight: "22px", textTransform: "uppercase" }}>

                        {isSuccess && <div className='alert alert-success alert-dismissible'>Password updated successfully.</div>}

                        {isResendSuccess && <div className='alert alert-success alert-dismissible'>Email sent successfully.</div>}

                        {modalState === 1 ?
                            <>
                                Enter account email*<input type="email" className="form-control login-input" style={{ fontStyle: "normal", fontFamily: 'Roboto', color: '#000', lineHeight: '24px' }} placeholder="example@email.com" onChange={handleEmailChange} />
                            </>
                            : modalState === 2 ?
                                <>
                                    ENTER TEMPORARY PASSWORD*<input type="text" value={tempPassword} className="form-control login-input" style={{ fontStyle: "normal", fontFamily: 'Roboto', color: '#000' }} placeholder="Enter temporary password" onChange={handleTempPasswordChange} />
                                </>
                                : <>
                                    ENTER NEW PASSWORD*<input type="password" value={newPassword1} className="form-control login-input" style={{ fontStyle: "normal", fontFamily: 'Roboto', color: '#000', marginBottom: '10px' }} placeholder="Enter password" onChange={handleNewPassword1Change} />
                                    RE-ENTER NEW PASSWORD*<input type="password" value={newPassword2}  className="form-control login-input" style={{ fontStyle: "normal", fontFamily: 'Roboto', color: '#000' }} placeholder="Re-Enter password" onChange={handleNewPassword2Change} />
                                </>
                        }
                    </div>
                    {emailError &&
                        <div style={{ color: 'red' }}>{emailError}</div>
                    }
                </Modal.Body>

                <Modal.Footer style={{ justifyContent: 'center', display: 'flex', gap: '1rem' }}>

                    <Btn
                        cancel
                        className="cancel-button"
                        onClick={() => {
                            props.setShowModal(false);
                            if (props.confirmNo) {
                                props.confirmNo();
                            }
                        }}> {props.noText ?? 'Cancel'}
                    </Btn>

                    <Btn confirm className="confirm-button" onClick={modalState === 1 ? email_submit : modalState === 2 ? tempPassword_submit : newPassword_submit} disabled={isProcessing}>
                        {isProcessing ? 'Processing...' : (props.yesText ?? 'Confirm')}
                    </Btn>

                    <Container>
                        <p>
                            {modalState === 2 && (
                                <>
                                    Don’t receive the email? Be sure to check your spam folder.{' '}
                                    <a href="#" style={{ textDecoration: 'underline' }} onClick={resendEmail}>Resend email</a>.
                                </>
                            )}
                            Questions? Email us at&nbsp;
                            <a href="mailto:helpdesk@myscopetech.com">helpdesk@myscopetech.com</a>
                        </p>
                    </Container>

                </Modal.Footer>
            </form>
        </Modal>
    );
}

const Btn = styled.div`
    height: 48px;
    width: 175px; 
    padding: 0 40px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal 400 16px / 21px Rajdhani;
    letter-spacing: 0.6px;
    border-radius: 3px;
    cursor: pointer;
    opacity: 1;

    ${(props) =>
        props.cancel &&
        `
        background-color: white;
        color: #212E33;
        border: 3px solid #E3E3E3;
    `}

    ${(props) =>
        props.confirm &&
        `
        background-color: #DE841E;
        color: white;
        border: none;
    `}
`;

const Container = styled.div`
    margin-top: 1rem;

    p {
        display: flex;
        flex-wrap: wrap;
        font-family: Roboto;
        font-weight:400;
        color: #212e3380;
        letter-spacing: 0px;
        opacity: 1;
        line-height: 24px;
        font-size: 16px;
        justify-content: center;
        text-align: center;
    }

    a {
        color:#DE841E;
        text-decoration: underline;
    }
    a:hover {
        color:#DE841E;
    }
`;