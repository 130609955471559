import React from 'react'
import Footer from '../Components/Footer/Footer'
import Login from '../Components/Login/Login'
import LoginMenu from '../Components/Navbar/LoginNavbar'
import { Helmet} from 'react-helmet'

export default function login() {
    return (
        <div>
             <Helmet>
                <meta charSet="utf-8" />
                <title>Prodocs | Login</title>
                <link rel="canonical" href="/prodocs-logo.png" />
            </Helmet>
            <LoginMenu/>
            <Login/>
            <Footer/>
        </div>
    )
}

